import React from "react"
import Layout from "../Components/layout"
// import Img from "gatsby-image"
import contact from "../constants/contact"
import ContactBanner from "../Components/inquirelink"

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import SEO from "../Components/SEO"

export default () => {
  return (
    <Layout>
      <SEO title="Contact Us" description="Connect With Fastlink"></SEO>
      <ContactBanner nobutton></ContactBanner>
      <Container class="contact-us">
        <Row>
          <Col md="5" className="py-5 px-5">
            <div className="map-container">
              <iframe
                title="FastLinkPH"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.7825274469506!2d121.04929671464927!3d14.611460880728089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b7c3441d7369%3A0x3ea104a2dd39d5ed!2s23%201st%20Ave%2C%20Quezon%20City%2C%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1619683165818!5m2!1sen!2sph"
                className="responsive-iframe"
                frameborder="0"
                allowfullscreen
                aria-hidden="false"
              ></iframe>
            </div>
            <p className="pt-3" style={{ color: "#2E2E2E" }}>
              Address: {contact.address} <br />
              <br />
              Contact Number: <br />
              +632 8287 4114 <br />
              +632 8254 1594 <br />
              +632 7720 1310 <br />
              +632 8726 9129 <br />
              +632 8584 1266 (FAX) <br />
              <br />
              SALES INQUIRY: {contact.emailInquiry} <br />
              SERVICE AND SUPPORT: {contact.emailHelpDesk} <br />
            </p>
          </Col>
          <Col
            md="7"
            className="flex text-center my-4 py-2"
            style={{
              backgroundColor: "#B7C7D0",
            }}
          >
            <p style={{ color: "#606060", fontSize: "32px" }}>
              CONNECT WITH US
            </p>

            <hr />
            <Form
              className="px-2"
              action="https://formspree.io/f/xbjpdzzq"
              method="POST"
            >
              <Row form>
                <Col md={5}>
                  <FormGroup className="text-left">
                    <Label for="email" className="text-left">
                      Email
                    </Label>
                    <Input
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="johndoe@company.com"
                    />
                  </FormGroup>
                </Col>
                <Col md={7}>
                  <FormGroup className="text-left">
                    <Label for="company">Company</Label>
                    <Input
                      required
                      type="text"
                      name="company"
                      id="company"
                      placeholder="Company Name"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup className="text-left">
                    <Label for="name">Name</Label>
                    <Input
                      required
                      type="text"
                      name="name"
                      id="name"
                      placeholder="John Doe"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="text-left">
                    <Label for="designation">Designation</Label>
                    <Input
                      required
                      type="text"
                      name="designation"
                      id="designation"
                      placeholder="Marketing Agent"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={4}>
                  <FormGroup className="text-left">
                    <Label for="contactNumber">Contact Number</Label>
                    <Input
                      required
                      type="tel"
                      name="contactNumber"
                      id="contactNumber"
                      placeholder="+63 ### ### ####"
                    />
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup className="text-left">
                    <Label for="forwardSelect">Forward: </Label>
                    <Input
                      required
                      type="select"
                      name="forwardSelect"
                      id="forwardSelect"
                    >
                      <option>To Sales (for Quotation)</option>
                      <option>
                        To Support and Services (for Technical Assistance)
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="text-left">
                <Label for="message">Message</Label>
                <Input
                  required
                  type="textarea"
                  rows="4"
                  res
                  name="message"
                  id="message"
                  style={{
                    resize: "none",
                  }}
                />
              </FormGroup>
              <Button type="submit" className="btn btn-primary text-left">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
