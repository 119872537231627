export default {
  facebook: "https://www.facebook.com/",
  youtube: "https://www.youtube.com/",
  email: "info@fastlink.com",
  emailInquiry: "inquiry@fastlink.ph",
  emailHelpDesk: "helpdesk@fastlink.ph",
  address:
    "#23, 1st Avenue, Brgy. Bagong Lipunan ng Crame, Cubao, Quezon City, Philippines",
  phone: "8287 4114",
}
